<template>
  <div class="tui-conversation-list">
    <ActionsMenu
      v-if="isShowOverlay"
      :selectedConversation="currentConversation"
      :actionsMenuPosition="actionsMenuPosition"
      :selectedConversationDomRect="currentConversationDomRect"
      @closeConversationActionMenu="closeConversationActionMenu"
    />
    <div
      class="conversation-list-title"
      style="
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #f4f5f9;
        font-size: 16px;
        font-weight: 600;
        color: #333;
        margin-left: 10px;
      "
    >
      我的会话
    </div>
    <el-radio-group v-model="userGroup" @input="userGroupInput" style="margin: 12px;">
      <el-radio-button label="patient">患者({{patientNum}})</el-radio-button>
      <el-radio-button label="doctor">医生({{ doctorNum }})</el-radio-button>
    </el-radio-group>
    <div
      v-for="(conversation, index) in newConversationList"
      :id="`convlistitem-${conversation.conversationID}`"
      :key="conversation.conversationID"
      :class="['TUI-conversation-content', !isPC && 'TUI-conversation-content-h5']"
    >
      <div
        v-if="userGroup == conversation.chatType"
        @click="enterConversationChat(conversation)"
        @longpress="showConversationActionMenu($event, conversation, index)"
        @contextmenu.prevent="
          showConversationActionMenu($event, conversation, index, true)
        "
        :class="[
          isPC && 'isPC',
          'TUI-conversation-item',
          currentConversationID === conversation.conversationID &&
            'TUI-conversation-item-selected',
          conversation.isPinned && 'TUI-conversation-item-pinned',
        ]"
      >
        <aside class="left">
          <Avatar
            useSkeletonAnimation
            :url="getAvatar(conversation)"
            flow="in"
            size="30px"
          />
          <div
            v-if="userOnlineStatusMap && isShowUserOnlineStatus(conversation)"
            :class="[
              'online-status',
              Object.keys(userOnlineStatusMap).length > 0 &&
              Object.keys(userOnlineStatusMap).includes(
                conversation.userProfile.userID
              ) &&
              userOnlineStatusMap[conversation.userProfile.userID].statusType === 1
                ? 'online-status-online'
                : 'online-status-offline',
            ]"
          ></div>
          <span class="num" v-if="conversation.unreadCount > 0 && !conversation.isMuted">
            {{ conversation.unreadCount > 99 ? "99+" : conversation.unreadCount }}
          </span>
          <span
            class="num-notify"
            v-if="conversation.unreadCount > 0 && conversation.isMuted"
          ></span>
        </aside>
        <div class="content">
          <div class="content-header">
            <label class="content-header-label">
              <p class="name">{{ conversation.getShowName() }}</p>
            </label>
            <div class="middle-box">
              <span
                class="middle-box-at"
                v-if="
                  conversation.type === 'GROUP' &&
                  conversation.groupAtInfoList &&
                  conversation.groupAtInfoList.length > 0
                "
                >{{ conversation.getGroupAtInfo() }}</span
              >
              <p class="middle-box-content">
                {{ getLastMessageContent(conversation) }}
              </p>
            </div>
          </div>
          <div class="content-footer">
            <span class="time">{{
              formatNoticeTime(conversation.lastMessage.lastTime * 1000)
            }}</span>
            <Icon v-if="conversation.isMuted" :file="muteIcon"></Icon>
          </div>
        </div>
      </div>
    </div>
    <el-empty
      :image-size="80"
      v-if="conversationList.length === 0"
      description="暂无咨询用户"
    ></el-empty>
  </div>
</template>

<script lang="ts">
interface IUserStatus {
  statusType: number;
  customStatus: string;
}
interface IUserStatusMap {
  [userID: string]: IUserStatus;
}
import { ref, watchEffect } from "../../../adapter-vue";
import TUIChatEngine, { TUIStore, StoreName, TUIUserService, TUIConversationService } from "@tencentcloud/chat-uikit-engine";
import Icon from "../../common/Icon.vue";
import { JSONToObject } from "../../../utils/index";
import Avatar from "../../common/Avatar/index.vue";
import ActionsMenu from "../actions-menu/index.vue";
import muteIcon from "../../../assets/icon/mute.svg";
import { getPatientsInfoByImIds, getDoctorInfoByImIds } from "@/api/user";
import { isPC, isH5, isUniFrameWork } from "../../../utils/env";
import { formatNoticeTime } from "../../../utils/convert";
import commonConfig from "@/config/common";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const currentConversation = ref<IConversationModel>();
  const currentConversationID = ref<string>();
  const currentConversationDomRect = ref<DOMRect>();
  const isShowOverlay = ref<boolean>(false);
  const conversationList = ref<IConversationModel[]>([]);
  const conversationListDomRef = ref<HTMLElement | undefined>();
  const actionsMenuPosition = ref<{
    top: number;
    left?: number;
    conversationHeight?: number;
  }>({
    top: 0
  });
  const displayOnlineStatus = ref(false); // 在线状态 默认关闭
  const userOnlineStatusMap = ref<IUserStatusMap>();
  const userGroup = ref<String>('patient');
  const newConversationList = ref<IConversationModel[]>([]);
  const patientNum = ref(0);
  const doctorNum = ref(0);
  let lastestOpenActionsMenuTime: number | null = null;
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: (id: string) => {
      currentConversationID.value = id;
    },
    conversationList: (list: Array<IConversationModel>) => {
      console.table("list======", list);
      if (list.length > 0) {
        // 如果患者头像没有默认显示熊猫
        list.map(item => {
          // if (item.type == "C2C") {
          //   TUIUserService.getUserProfile({
          //     userIDList: [item.userProfile.userID],
          //   }).then((res) => {
          //     console.log('res======',res)
          //     item.userProfile.avatar = res.data[0].avatar;
          //     if (!item.userProfile.avatar) {
          //       item.userProfile.avatar =
          //         commonConfig.$filePublicUrl + "miniprogram/noplan-panda.svg";
          //     }
          //   });
          // }
          return item;
        });
      }
      conversationList.value = list;
    },
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
    }
  });
  watchEffect(() => {
    let userIds = conversationList.value.map((item: any) => item.userProfile?.userID);

    // 通过后台查询用户昵称
    if (userIds.length > 0) {
      getPatientsInfoByImIds(userIds).then((res: any) => {
        console.log("res=======", res);
        const data = res.res;
        patientNum.value = 0;
        conversationList.value.map((item: any) => {
          const patientInfo = data.find((i: any) => item.type == "C2C" && i.imId === item.userProfile.userID);
          if (patientInfo) {
            patientNum.value = patientNum.value + 1;
            item.userProfile.nick = patientInfo.name;
            item.chatType = 'patient';
          }
          return item;
        });
        newConversationList.value = [...conversationList.value];
      });
      console.log('getPatientsInfoByImIds conversationList.value---', conversationList.value);
      getDoctorInfoByImIds(userIds).then((res: any) => {
        console.log("getDoctorInfoByImIdsres=======", res);
        const data = res.res;
        doctorNum.value = 0;
        conversationList.value.map((item: any) => {
          const patientInfo = data.find((i: any) => item.type == "C2C" && i.imId === item.userProfile.userID);
          if (patientInfo) {
            item.userProfile.nick = patientInfo.doctorName;
            item.chatType = 'doctor';
            doctorNum.value = doctorNum.value + 1;
            // item = {
            //   ...patientInfo,
            //   chatType: 'doctor'
            // }
          }
          return item;
        });
        newConversationList.value = [...conversationList.value];
      });
    }
  });

  // 初始状态
  TUIStore.watch(StoreName.USER, {
    displayOnlineStatus: (status: boolean) => {
      displayOnlineStatus.value = status;
    },
    userStatusList: (list: Map<string, IUserStatus>) => {
      if (list.size !== 0) {
        userOnlineStatusMap.value = [...list.entries()].reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, ({} as IUserStatusMap));
      }
    }
  });
  const isShowUserOnlineStatus = (conversation: IConversationModel): boolean => {
    return displayOnlineStatus.value && conversation.type === TUIChatEngine.TYPES.CONV_C2C;
  };
  const showConversationActionMenu = (event: Event, conversation: IConversationModel, index: number, isContextMenuEvent?: boolean) => {
    if (isContextMenuEvent && isUniFrameWork) {
      return;
    }
    currentConversation.value = conversation;
    lastestOpenActionsMenuTime = Date.now();
    getActionsMenuPosition(event, index);
  };
  const closeConversationActionMenu = () => {
    // 防止连续触发overlay的tap事件
    if (lastestOpenActionsMenuTime && Date.now() - lastestOpenActionsMenuTime > 300) {
      currentConversation.value = undefined;
      isShowOverlay.value = false;
    }
  };
  const getLastMessageContent = (conversation: IConversationModel) => {
    // 获取最后消息内容
    if (conversation) {
      if (conversation.lastMessage.isRevoked) return `${conversation.lastMessage.nick}撤回了一条消息`;
      if (conversation.lastMessage.type == "TIMImageElem") return "[图片]";else if (conversation.lastMessage.type == "TIMVideoElem" || conversation.lastMessage.type == "TIMVideoFileElem") return "[视频]";else if (conversation.lastMessage.type == "TIMAudioElem" || conversation.lastMessage.type == "TIMSoundElem") return "[语音]";else if (conversation.lastMessage.type == "TIMFileElem") return "[文件]";else if (conversation.lastMessage.type == "TIMTextElem") return conversation.lastMessage.payload.text;else if (conversation.lastMessage.type == "TIMCustomElem") {
        // 自定义消息
        if (conversation.lastMessage.payload.data == "notification") return conversation.lastMessage.payload.description;
        if (conversation.lastMessage.payload.data == "doctorInfo") {
          // 推荐康复师给患者
          let doctorInfo = JSONToObject(conversation.lastMessage.payload.extension);
          if (doctorInfo) {
            return `您已为患者推荐康复师【${doctorInfo.name}】`;
          }
        }
        if (conversation.lastMessage.payload.data == "mallGoods") {
          // 推荐康复师给患者
          let goodsInfo = JSONToObject(conversation.lastMessage.payload.extension);
          console.log('goodsInfo=====', goodsInfo);
          if (goodsInfo) {
            if (goodsInfo.goodsType == 5) return `您已为患者推荐设备【${goodsInfo.name}】`;else return `您已为患者推荐方案【${goodsInfo.name}】`;
          }
        }
        if (conversation.lastMessage.payload.data == "orderInfo") {
          return `已收到所咨询订单`;
        }
      }
    }
  };
  const getActionsMenuPosition = (event: Event, index: number, conversation?: any) => {
    if (isUniFrameWork) {
      if (typeof conversationListDomRef.value === "undefined") {
        emits("getPassingRef", conversationListDomRef);
      }
      const query = uni.createSelectorQuery().in(conversationListDomRef.value);
      query.select(`#convlistitem-${index}`).boundingClientRect(data => {
        if (data) {
          actionsMenuPosition.value = {
            // uni-h5的uni-page-head不被认为是视窗中的成员，因此手动上head的高度
            top: data.bottom + (isH5 ? 44 : 0),
            left: event.touches[0].pageX,
            conversationHeight: data.height
          };
          isShowOverlay.value = true;
        }
      }).exec();
    } else {
      // 处理Vue原生
      const rect = event.currentTarget?.getBoundingClientRect();
      if (rect) {
        actionsMenuPosition.value = {
          top: rect.bottom,
          left: isPC ? event.clientX : undefined,
          conversationHeight: rect.height
        };
      }
      isShowOverlay.value = true;
    }
  };
  const enterConversationChat = (conversationInfo: any) => {
    emits("handleSwitchConversation", conversationInfo);
    TUIConversationService.switchConversation(conversationInfo.conversationID);
  };
  const getAvatar = (conversation: any) => {
    // 点对点，取用户头像
    if (conversation.type == "C2C") {
      // 判断头像是否是url
      if (/^(http|https):\/\/[^ "]+$/.test(conversation.userProfile.avatar)) {
        // 判断头像是否是svg文件
        if (/\.(svg)$/.test(conversation.userProfile.avatar.toLowerCase())) {
          return conversation.userProfile.avatar;
        } else {
          // 七牛云图片，指定宽度100，高度等比缩小
          return conversation.userProfile.avatar + '?imageView2/2/h/100';
        }
      } else {
        // 七牛云图片，指定宽度100，高度等比缩小
        return commonConfig.$filePublicUrl + conversation.userProfile.avatar + '?imageView2/2/h/100';
      }
    } else {
      // 取聊天时，取群头像
      return conversation.groupProfile.avatar;
    }
  };
  const userGroupInput = (e: string) => {
    if (e == 'patient') {} else if (e == 'doctor') {}
    console.log('userGroupInput', e);
  };
  // 暴露给父组件，当监听到滑动事件时关闭actionsMenu
  return Object.assign({
    muteIcon,
    isPC,
    formatNoticeTime,
    currentConversation,
    currentConversationID,
    currentConversationDomRect,
    isShowOverlay,
    conversationList,
    actionsMenuPosition,
    userOnlineStatusMap,
    userGroup,
    newConversationList,
    patientNum,
    doctorNum,
    isShowUserOnlineStatus,
    showConversationActionMenu,
    closeConversationActionMenu,
    getLastMessageContent,
    enterConversationChat,
    getAvatar,
    userGroupInput
  }, {
    closeChildren: closeConversationActionMenu
  });
};
__sfc_main.components = Object.assign({
  ActionsMenu,
  Avatar,
  Icon
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped src="./style/index.scss"></style>
